import {isPlatformBrowser} from '@angular/common';
import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '@environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {SocialMedia} from '@shared/domain/social-media';
import {MenuOptions} from '@shared/enums/menu-options.enum';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent
{
    private readonly isBrowserPlatform: boolean;
    public currentYear: number = new Date().getFullYear();
    public menuOptions = MenuOptions;
    public submitted = false;
    public formEmail: FormGroup;
    public message: string;
    public icon: string;
    public socialMedia = SocialMedia;

    public constructor(
        private readonly translate: TranslateService,
        @Inject(PLATFORM_ID) platformId: any)
    {
        this.isBrowserPlatform = isPlatformBrowser(platformId);

        this.formEmail = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email])
        });
    }

    public backToTop(): void
    {
        if (this.isBrowserPlatform)
        {
            window.scrollTo(0, 0);
        }
    }

    public getLink(websiteSection: MenuOptions): string
    {
        return `/${this.translate.currentLang}/${websiteSection}`;
    }

    public submitForm(): void
    {
        this.submitted = false;
        setTimeout(() =>
        {
            this.submitted = false;
        }, 5000);

        if (this.formEmail.valid)
        {
            this.message = this.translate.instant('FOOTER.NEWSLETTER_SUBMITTED');
            this.icon = '/assets/images/check-white.svg';

            this.submitted = true;

            if (this.isBrowserPlatform)
            {
                window.open(`${environment.newsletterSubmissionLink}&MERGE0=${this.formEmail.getRawValue().email}`, '_blank');
            }

            this.formEmail.reset();
        }
        else
        {
            this.message = this.translate.instant('FOOTER.NEWSLETTER_ERROR_EMAIL');
            this.icon = '/assets/images/error-info.svg';

            this.submitted = true;
        }
    }
}
