<footer>
    <div class="subscribe-newsletter d-flex justify-content-between align-items-center w-100">
        <div>
            <h2 class="newsletter-title" data-aos="fade-up">
                {{'FOOTER.TITLE' | translate}}
            </h2>
            <h3 class="newsletter-subtitle" data-aos="fade-up">
                {{'FOOTER.SUBTITLE' | translate}}
            </h3>
        </div>
        <form [formGroup]="formEmail">
            <div class="subscribe d-flex align-items-center g-30" data-aos="fade-up">
                <input type="email" name="newsletter-subscription" id="subscription" placeholder="nome.apelido@mail.com"
                    formControlName="email">
                <app-navigation-button iconSrc="/assets/images/icons/paperplane.svg" class="submit-newsletter-button"
                    (click)="submitForm()"></app-navigation-button>
            </div>
            <div class="feedback" *ngIf="submitted">
                <svg-icon class="icon" [src]="icon"></svg-icon>
                <span class="message">{{ message }}</span>
            </div>
        </form>
    </div>
    <div class="main-footer-section d-flex g-144">
        <div class="d-flex flex-column g-24">
            <svg-icon src="/assets/images/app-logo.svg" data-aos="fade-up"></svg-icon>
            <div class="d-flex align-items-center g-24 socials" data-aos="fade-up">
                <app-navigation-button iconSrc="/assets/images/linkedin.svg" [urlSrc]="socialMedia.LINKEDIN"
                    class="socials-button"></app-navigation-button>
                <app-navigation-button iconSrc="/assets/images/facebook.svg" [urlSrc]="socialMedia.FACEBOOK"
                    class="socials-button"></app-navigation-button>
            </div>
        </div>
        <div class="menus-mobile d-flex g-144">
            <div class="map-section d-flex flex-column g-16 align-items-start">
                <p data-aos="fade-up">{{'FOOTER.MAP' | translate}}</p>
                <a [routerLink]="getLink(menuOptions.ABOUT)" class="map-option" data-aos="fade-up">{{'MENU.ABOUT' |
                    translate}}</a>
                <a [routerLink]="getLink(menuOptions.MISSION)" class="map-option" data-aos="fade-up">{{'MENU.MISSION' |
                    translate}}</a>
                <a [routerLink]="getLink(menuOptions.SERVICES)" class="map-option" data-aos="fade-up">{{'MENU.SERVICES'
                    | translate}}</a>
                <a [routerLink]="getLink(menuOptions.EVENTS)" class="map-option" data-aos="fade-up">{{'MENU.EVENTS' |
                    translate}}</a>
                <a [routerLink]="getLink(menuOptions.NEWS)" class="map-option" data-aos="fade-up">{{'MENU.NEWS' |
                    translate}}</a>
                <a [routerLink]="getLink(menuOptions.CONTACTS)" class="map-option" data-aos="fade-up">{{'MENU.CONTACTS'
                    | translate}}</a>
            </div>
            <div class="legal-section d-flex flex-column g-16 align-items-start">
                <p data-aos="fade-up">{{'FOOTER.LEGAL' | translate}}</p>
                <a href="/assets/images/cookie-policy.pdf" target="_blank" class="legal-option"
                    data-aos="fade-up">{{'FOOTER.COOKIE_POLICY' |
                    translate}}</a>
                <a href="/assets/images/privacy-policy.pdf" target="_blank" class="legal-option"
                    data-aos="fade-up">{{'FOOTER.PRIVACY_POLICY' |
                    translate}}</a>
            </div>
        </div>
        <div class="d-flex flex-column g-24">
            <div class="location d-flex g-20" data-aos="fade-up">
                <svg-icon src="/assets/images/icons/location.svg"></svg-icon>
                <span>{{'FOOTER.LOCATION' | translate}}</span>
            </div>
            <div class="email d-flex g-20" data-aos="fade-up">
                <svg-icon src="/assets/images/icons/message.svg"></svg-icon>
                <a href="mailto:{{'FOOTER.EMAIL' | translate}}" target="_blank">{{'FOOTER.EMAIL' | translate}}</a>
            </div>
            <app-custom-button class="project-file" message="BUTTONS.PROJECT_FILE"
                linkReference="/assets/images/connect5-project-file.pdf" [hasFileLink]="true"
                data-aos="fade-up"></app-custom-button>
        </div>
    </div>
    <div class="financing d-flex justify-content-between w-100">
        <div class="logos d-flex g-48 align-items-center">
            <div class="d-flex g-48 align-items-center">
                <svg-icon src="/assets/images/financing/edih.svg" data-aos="fade-up"></svg-icon>
                <svg-icon src="/assets/images/financing/prr.svg" data-aos="fade-up"></svg-icon>
            </div>
            <div class="d-flex g-48 align-items-center">
                <svg-icon src="/assets/images/financing/rp.svg" data-aos="fade-up"></svg-icon>
                <svg-icon src="/assets/images/financing/ue.svg" data-aos="fade-up"></svg-icon>
            </div>
        </div>
        <div class="d-flex align-items-center g-60" data-aos="fade-up">
            <app-language-selector></app-language-selector>
            <app-navigation-button iconSrc="/assets/images/arrow-right.svg" class="back-to-top"
                (click)="backToTop()"></app-navigation-button>
        </div>
    </div>
    <div class="reserved-rights d-flex justify-content-between w-100">
        <span>© {{currentYear}} {{'FOOTER.RIGHTS' | translate}}</span>
        <a href="https://load.digital/" target="_blank">{{'FOOTER.MADE_BY' | translate}}</a>
    </div>
</footer>