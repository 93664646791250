<div class="banner position-relative w-100" [ngClass]="{'full-size-banner': bannerDescription}">
    <h3 class="page-epic" *ngIf="bannerEpic" data-aos="fade-up">
        {{bannerEpic | translate}}
    </h3>
    <h1 class="title" *ngIf="bannerTitle" data-aos="fade-up">
        {{bannerTitle | translate}}
    </h1>
    <h2 class="subtitle" *ngIf="bannerSubTitle" data-aos="fade-up">
        {{bannerSubTitle | translate}}
    </h2>
    <div class="description" *ngIf="bannerDescription" data-aos="fade-up">
        <p>
            {{bannerDescription | translate}}
        </p>
        <app-custom-button [linkReference]="linkReference" message="BUTTONS.CONTACT" *ngIf="showContactButton" [modal]="buttonContent" data-aos="fade-up"></app-custom-button>
    </div>
    <div class="position-absolute scroll-indicator d-flex align-items-center" [ngClass]="{'homepage-indicator': isHomepageBanner()}" data-aos="fade-up">
        <img src="/assets/images/scroll-animation.gif" alt="">
        <span class="scroll-helper">{{'HOMEPAGE.BANNER.SCROLL' | translate}}</span>
    </div>
</div>