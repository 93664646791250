import {CommonModule} from '@angular/common';
import {HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GoogleMapsModule} from '@angular/google-maps';
import {RouterModule} from '@angular/router';
import {NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {ApplicationModalComponent} from './application-modal/application-modal.component';
import {BannerComponent} from './banner/banner.component';
import {ConsortiumComponent} from './consortium/consortium.component';
import {CustomButtonComponent} from './custom-button/custom-button.component';
import {CustomCardComponent} from './custom-card/custom-card.component';
import {GovernanceModalComponent} from './governance-modal/governance-modal.component';
import {LanguageSelectorComponent} from './language-selector/language-selector.component';
import {NavigationButtonComponent} from './navigation-button/navigation-button.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {PaginationComponent} from './pagination/pagination.component';
import {PreloaderComponent} from './preloader/preloader.component';
import {ProgressBarComponent} from './progress-bar/progress-bar.component';

@NgModule({
    declarations: [
        CustomButtonComponent,
        NavigationButtonComponent,
        LanguageSelectorComponent,
        ConsortiumComponent,
        BannerComponent,
        GovernanceModalComponent,
        CustomCardComponent,
        PaginationComponent,
        ApplicationModalComponent,
        ProgressBarComponent,
        PreloaderComponent,
        PageNotFoundComponent
    ],
    imports:
        [
            TranslateModule,
            RouterModule,
            FormsModule,
            ReactiveFormsModule,
            CommonModule,
            AngularSvgIconModule,
            HttpClientModule,
            GoogleMapsModule,
            AccordionModule,
            NgbNavModule
        ],
    exports:
        [
            CommonModule,
            TranslateModule,
            RouterModule,
            FormsModule,
            ReactiveFormsModule,
            AngularSvgIconModule,
            HttpClientModule,
            HttpClientJsonpModule,
            CustomButtonComponent,
            NavigationButtonComponent,
            LanguageSelectorComponent,
            ConsortiumComponent,
            GoogleMapsModule,
            BannerComponent,
            AccordionModule,
            CustomCardComponent,
            PaginationComponent,
            ProgressBarComponent,
            NgbNavModule,
            PreloaderComponent,
            PageNotFoundComponent
        ]
})
export class SharedModule
{ }
