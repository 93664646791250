<div class="modal-detailed-info position-relative">
    <h3 class="modal-title">
        {{'ABOUT.GOVERNANCE.' + governanceArea + '.TITLE' | translate}}
    </h3>
    <p class="modal-description">
        {{'ABOUT.GOVERNANCE.' + governanceArea + '.DESCRIPTION' | translate}}
    </p>
    <div class="cross-holder position-absolute">
        <button class="cross d-flex flex-column position-abolute" (click)="dismiss()">
            <span class="cross-line w-100"></span>
            <span class="cross-line w-100"></span>
        </button>
    </div>
</div>
<div class="members-holder d-flex flex-column g-16">
    <div class="member d-flex align-items-center" *ngFor="let member of memberList">
        <img class="member-avatar" src="/assets/images/governance/{{member.avatar}}.webp" alt="" *ngIf="member.avatar">
        <div class="d-flex flex-column justify-content-between w-100">
            <div class="d-flex justify-content-between flex-column-reverse">
                <p class="member-name">{{member.name}}</p>
                <p class="member-function" *ngIf="member.function">{{member.function}}</p>
            </div>
            <p class="member-company">{{member.company}}</p>
        </div>
    </div>
</div>