import {HttpClient} from '@angular/common/http';
import {StateKey, TransferState, makeStateKey} from '@angular/platform-browser';
import {TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {Observable} from 'rxjs';

class TranslateClientLoader implements TranslateLoader
{
    public constructor(private readonly http: HttpClient, private readonly transferState: TransferState)
    { }

    public getTranslation(lang: string): Observable<any>
    {
        const key: StateKey<number> = makeStateKey<number>(`transfer-translate-${lang}`);
        const data = this.transferState.get(key, null);

        if (data)
        {
            return new Observable((observer) =>
            {
                observer.next(data);
                observer.complete();
            });
        }
        else
        {
            return new TranslateHttpLoader(this.http).getTranslation(lang);
        }
    }
}

export function translateClientLoaderFactory(httpClient: HttpClient, transferState: TransferState): TranslateClientLoader
{
    return new TranslateClientLoader(httpClient, transferState);
}
