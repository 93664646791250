import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Language} from '@shared/enums/language';

@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit
{
    private mainLanguage: string;

    public constructor(
        private readonly translate: TranslateService,
        private readonly location: Location,
        private readonly router: Router)
    { }

    public ngOnInit(): void 
    {
        this.mainLanguage = this.translate.currentLang;
    }

    public switchLanguage(): void
    {
        const language = this.translate.currentLang === Language.PT ? Language.EN : Language.PT;
        const currentUrl = this.router.url;

        const currentArea = currentUrl.split('/').slice(2).join('/');

        this.translate.use(language);
        this.mainLanguage = language;
        this.location.replaceState(`${language}/${currentArea}`);
    }

    public isActiveLanguage(language: string): boolean
    {
        return this.translate.currentLang === language;
    }
}
