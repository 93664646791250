import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {MenuOptions} from '@shared/enums/menu-options.enum';
import {ModalType} from '@shared/enums/modal-type';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent
{
    @Input() public linkReference: string;
    @Input() public bannerTitle: string;
    @Input() public bannerSubTitle: string;
    @Input() public bannerDescription: string;
    @Input() public bannerEpic: string;
    @Input() public showContactButton: boolean;
    @Input() public buttonContent: ModalType;

    public constructor(private readonly router: Router)
    { }

    public isHomepageBanner(): boolean
    {
        return this.router.url.includes(MenuOptions.HOME);
    }
}
