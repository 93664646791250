import {isPlatformServer} from '@angular/common';
import {Component, Inject, OnInit, Optional, PLATFORM_ID} from '@angular/core';
import {RESPONSE} from '@nguniversal/express-engine/tokens';
import {TranslateService} from '@ngx-translate/core';
import {MenuOptions} from '@shared/enums/menu-options.enum';
import {Response} from 'express';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit
{

    public constructor(private readonly translateService: TranslateService,
        @Optional() @Inject(RESPONSE) private readonly response: Response,
        @Inject(PLATFORM_ID) private readonly platformId: any)
    { }

    public ngOnInit(): void
    {
        if (isPlatformServer(this.platformId))
        {
            this.response.status(404);
        }
    }

    public getHomeUrl(): string
    {
        return `/${this.translateService.currentLang}/${MenuOptions.HOME}`;
    }
}
