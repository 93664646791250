import {Component} from '@angular/core';

@Component({
    selector: 'app-consortium',
    templateUrl: './consortium.component.html',
    styleUrls: ['./consortium.component.scss']
})
export class ConsortiumComponent
{
    public consortiumListUp: string[] = ['ceiia', 'dtx', 'ipl', 'ipp', 'tice', 'ua'];
    public consortiumListDown: string[] = ['ubi', 'uc', 'um', 'up', 'it', 'ipv'];
}
