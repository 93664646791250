import {Injectable} from '@angular/core';
import {HttpRequest,  HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoadingService} from './loading.service';
import {DOWNLOAD_REQUEST} from 'src/app/news/download-request-content';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor 
{
    public constructor(private loadingService: LoadingService) 
    {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> 
    {
        const downloadRequest = DOWNLOAD_REQUEST;
        const isNotSvgRequest = !this.isSvgRequest(request.url);
        const isNotDownload = !request.context.get(downloadRequest);
        const isNotPostRequest = !this.isPostRequest(request);

        if (isNotSvgRequest && isNotDownload && isNotPostRequest) 
        {
            this.loadingService.showLoader();
        }

        return next.handle(request).pipe(finalize(() => 
        {
            if (isNotSvgRequest && isNotDownload && isNotPostRequest) 
            {
                this.loadingService.hideLoader();
            }
        }));
    }

    private isSvgRequest(url: string): boolean 
    {
        return url.endsWith('.svg');
    }

    private isPostRequest(request: HttpRequest<any>): boolean
    {
        return request.method === 'POST';
    }
}
