import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule, TransferState} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {translateClientLoaderFactory} from '@shared/loaders/translate-client.loader';
import {LoadingInterceptor} from '@shared/preloader/loading-interceptor';
import {LoadingService} from '@shared/preloader/loading.service';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {register} from 'swiper/element/bundle';
import {RoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CookieBarComponent} from './cookie-bar/cookie-bar.component';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {SharedModule} from './shared/shared.module';

register();

@NgModule({
    declarations:
        [
            AppComponent,
            HeaderComponent,
            FooterComponent,
            CookieBarComponent
        ],
    imports:
        [
            BrowserModule.withServerTransition({appId: 'serverApp'}),
            BrowserAnimationsModule,
            SharedModule,
            HttpClientModule,
            RoutingModule,
            AccordionModule.forRoot(),
            TranslateModule.forRoot({
                loader:
                {
                    provide: TranslateLoader,
                    useFactory: translateClientLoaderFactory,
                    deps: [HttpClient, TransferState]
                }
            }),
            NgbModule,
            AngularSvgIconModule.forRoot()
        ],
    providers: [
        LoadingService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule
{ }
