import {Component, ElementRef, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbNav, NgbNavChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {ApplicationModalTab} from './application-modal-tab.enum';
import {ApplicationModalNavigation} from './application-modal-navigation.enum';
import {ApplicationService} from './application.service';

@Component({
  selector: 'app-application-modal',
  templateUrl: './application-modal.component.html',
  styleUrls: ['./application-modal.component.scss']
})
export class ApplicationModalComponent
{
    public applicationForm: FormGroup;
    public modalNavigation = ApplicationModalNavigation;
    @ViewChild('scrollNav') public scrollNav: ElementRef;
    @ViewChild('aplicationForm') public aplicationForm: ElementRef;
    public applicationModalTab = ApplicationModalTab;
    public submitted = false;
    public submiting = false;

    public constructor(
        private readonly activeModal: NgbActiveModal,
        private readonly elementRef: ElementRef,
        private readonly applicationService: ApplicationService
        )
    {
        this.applicationForm = new FormGroup({
            nameOfOrganization: new FormControl('', Validators.required),
            nif: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9), Validators.maxLength(9)]),
            numberOfEmployees: new FormControl('', Validators.required),
            businessOffer: new FormControl('', Validators.required),
            digitalizationOpportunities: new FormControl('', Validators.required),
            publicFunding: new FormControl('no', Validators.required),

            digitalizationChallengesOrganization: new FormControl('', Validators.required),
            specificDigitalizationCase: new FormControl('', Validators.required),
            digitalizeTeamResources: new FormControl('', Validators.required),
            expectedResultsDigitalizeMission: new FormControl('', Validators.required),
            supportNeededDigitalizeMissionResults: new FormControl('', Validators.required),

            nameOfContact: new FormControl('', Validators.required),
            roleOfContact: new FormControl('', Validators.required),
            emailOfContact: new FormControl('', [Validators.required, Validators.email]),
            phoneOfContact: new FormControl('', [Validators.required, Validators.pattern(/^(\+\d{1,3}\s?)?\d{1,14}$/), Validators.minLength(9)]),
        });
    }

    public dismiss(): void
    {
        this.activeModal.dismiss();
    }

    public showButton(navigation: NgbNavChangeEvent, button: ApplicationModalNavigation): boolean
    {
        switch (button)
        {
            case ApplicationModalNavigation.PREVIOUS:
                return navigation.activeId !== ApplicationModalTab.ORGANIZATION;
            case ApplicationModalNavigation.NEXT:
                return navigation.activeId !== ApplicationModalTab.CONTACT;
            case ApplicationModalNavigation.SUBMIT:
            case ApplicationModalNavigation.CANCEL:
                return navigation.activeId === ApplicationModalTab.CONTACT;
            default:
                return false;
        }
    }

    public move(navigation: NgbNav): void 
    {
        if (this.isMobile())
        {
            this.aplicationForm.nativeElement.scrollTop = 0;

            if (navigation.activeId === ApplicationModalTab.ORGANIZATION)
            {
                this.scrollNav.nativeElement.scrollLeft = 0;
            }
            else if (navigation.activeId === ApplicationModalTab.INFORMATION)
            {
                this.scrollNav.nativeElement.scrollLeft = 270 - ((this.scrollNav.nativeElement.clientWidth - 270 - 8) / 2);
            }
            else if (navigation.activeId === ApplicationModalTab.CONTACT)
            {
                this.scrollNav.nativeElement.scrollLeft = this.scrollNav.nativeElement.scrollWidth;
            }
        }
    }

    public cancel(): void
    {
        this.applicationForm.reset();
        this.activeModal.close();
    }

    public next(navigation: NgbNav): void
    {
        navigation.select(navigation.activeId + 1);
        this.move(navigation);
    }

    public previous(navigation: NgbNav): void
    {
        navigation.select(navigation.activeId - 1);
        this.move(navigation);
    }

    public sendEmail(): void
    {
        this.submiting = true;
        const emailData = {
            to: this.applicationForm.get('emailOfContact').value,
            nameOfOrganization: this.applicationForm.get('nameOfOrganization').value,
            nif: this.applicationForm.get('nif').value,
            numberOfEmployees: this.applicationForm.get('numberOfEmployees').value,
            businessOffer: this.applicationForm.get('businessOffer').value,
            digitalizationOpportunities: this.applicationForm.get('digitalizationOpportunities').value,
            publicFunding: this.applicationForm.get('publicFunding').value,
            digitalizationChallengesOrganization: this.applicationForm.get('digitalizationChallengesOrganization').value,
            specificDigitalizationCase: this.applicationForm.get('specificDigitalizationCase').value,
            digitalizeTeamResources: this.applicationForm.get('digitalizeTeamResources').value,
            expectedResultsDigitalizeMission: this.applicationForm.get('expectedResultsDigitalizeMission').value,
            supportNeededDigitalizeMissionResults: this.applicationForm.get('supportNeededDigitalizeMissionResults').value,
            nameOfContact: this.applicationForm.get('nameOfContact').value,
            roleOfContact: this.applicationForm.get('roleOfContact').value,
            emailOfContact: this.applicationForm.get('emailOfContact').value,
            phoneOfContact: this.applicationForm.get('phoneOfContact').value
        };

        this.applicationService.sendEmail(emailData).subscribe({
            next: () => 
            {
                this.submitted = true;
            }
        }); 

    }

    public onSubmit(navigation: NgbNav): void
    {
        if (this.applicationForm.valid)
        {
            this.sendEmail();
        }
        else
        {
            const controls = this.applicationForm.controls;

            const organizationFormControlNames = ['nameOfOrganization', 'nif', 'numberOfEmployees','businessOffer', 'digitalizationOpportunities', 'publicFunding'];
            const informationFormControlNames = ['digitalizationChallengesOrganization', 'specificDigitalizationCase', 'digitalizeTeamResources', 'expectedResultsDigitalizeMission', 'supportNeededDigitalizeMissionResults'];

            const invalidFormControlName = Object.keys(controls).find((formControlName: string) => controls[formControlName].invalid);
            let invalidTab = ApplicationModalTab.CONTACT;

            if (organizationFormControlNames.includes(invalidFormControlName))
            {
                invalidTab = ApplicationModalTab.ORGANIZATION;
            }
            else if (informationFormControlNames.includes(invalidFormControlName))
            {
                invalidTab = ApplicationModalTab.INFORMATION;
            }

            navigation.select(invalidTab);
            this.move(navigation);

            this.applicationForm.controls[invalidFormControlName].markAsTouched();
            this.applicationForm.controls[invalidFormControlName].markAsDirty();

            const firstInvalidInput = this.elementRef.nativeElement.querySelector('.input.ng-invalid') as HTMLInputElement;

            setTimeout(() => 
            {
                if (firstInvalidInput)
                {
                    firstInvalidInput.focus();
                }
            }, 150);
        }
    }

    public isMobile(): boolean
    {
        return window.innerWidth < 768;
    }

    public showErrorInput(input: string): boolean
    {
        return this.applicationForm.get(input).invalid && (this.applicationForm.get(input).touched);
    }
}
