import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApplicationModalComponent} from '@shared/application-modal/application-modal.component';
import {GovernanceAreas} from '@shared/enums/governance-area.enum';
import {ModalType} from '@shared/enums/modal-type';
import {GovernanceModalComponent} from '@shared/governance-modal/governance-modal.component';
import {noop} from 'rxjs';

@Component({
    selector: 'app-custom-button',
    templateUrl: './custom-button.component.html',
    styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent
{
    @Input() public message: string;
    @Input() public linkReference: string;
    @Input() public fragment: string;
    @Input() public governanceArea: GovernanceAreas;
    @Input() public modal: ModalType;
    @Input() public customButton = false;
    @Input() public showIcon = true;
    @Input() public hasFileLink = false;

    public constructor(
        private readonly modalService: NgbModal,
        private readonly router: Router
    )
    { }

    public navigateToLink(): void
    {
        if (this.linkReference.includes('mailto'))
        {
            window.location.href = this.linkReference;
        }
        else if (this.fragment)
        {
            this.router.navigate([this.linkReference], {fragment: this.fragment});
        }
        else
        {
            this.router.navigate([this.linkReference]);
        }
    }

    public navigateTo(): void
    {
        switch (this.modal)
        {
            case ModalType.GOVERNANCE:
                const modalRefGovernance = this.modalService.open(GovernanceModalComponent, {modalDialogClass: 'governance-modal', scrollable: true});
                modalRefGovernance.componentInstance.governanceArea = this.governanceArea;
                modalRefGovernance.result.then(noop, noop);
                break;
            case ModalType.APPLICATION:
                const modalRefApplication = this.modalService.open(ApplicationModalComponent, {modalDialogClass: 'application-modal modal-xl', scrollable: true});
                modalRefApplication.result.then(noop, noop);
                break;
            default:
                break;
        }
    }
}
