<div class="consortium d-flex">
    <div class="partner-section w-50 d-flex position-relative">
        <div class="partners w-50 d-flex flex-column" data-aos="fade-up">
            <div class="partner-track-up">
                <div class="slide" *ngFor="let consortiumPartner of consortiumListUp; let last = last" [ngClass]="{'last-logo': last}">
                    <img src="/assets/images/consortium/{{consortiumPartner}}.webp" alt="">
                </div>
            </div>
            <div class="partner-track-up">
                <div class="slide" *ngFor="let consortiumPartner of consortiumListUp; let last = last" [ngClass]="{'last-logo': last}">
                    <img src="/assets/images/consortium/{{consortiumPartner}}.webp" alt="">
                </div>
            </div>
        </div>
        <div class="partners w-50 d-flex flex-column justify-content-end" data-aos="fade-up">
            <div class="partner-track-down">
                <div class="slide" *ngFor="let consortiumPartner of consortiumListDown; let last = last" [ngClass]="{'last-logo': last}">
                    <img src="/assets/images/consortium/{{consortiumPartner}}.webp" alt="">
                </div>
            </div>
            <div class="partner-track-down">
                <div class="slide" *ngFor="let consortiumPartner of consortiumListDown; let last = last" [ngClass]="{'last-logo': last}">
                    <img src="/assets/images/consortium/{{consortiumPartner}}.webp" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="consortium-information w-50">
        <h2 class="consortium-title" data-aos="fade-up">
            {{'CONSORTIUM.TITLE' | translate}}
        </h2>
        <p class="consortium-description" data-aos="fade-up">
            {{'CONSORTIUM.INFORMATION' | translate}}
        </p>
    </div>
</div>