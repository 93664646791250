import {animate, style, transition, trigger} from '@angular/animations';
import {isPlatformBrowser} from '@angular/common';
import {Component, Inject, OnDestroy, PLATFORM_ID} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Language} from '@shared/enums/language';
import {LoadingService} from '@shared/preloader/loading.service';
import AOS from 'aos';
import {Subscription, noop} from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    animations: [
        trigger('outAnimation',
            [
                transition('* => void',
                    [
                        style({opacity: 1}),
                        animate('0.5s ease-in', style({opacity: 0}))
                    ]
                )
            ]
        )
    ]
})
export class AppComponent implements OnDestroy
{
    public lang = '';
    private globalUrl = '';
    private readonly isPlatformBrowser: boolean;
    private readonly routeSubscription: Subscription;

    public constructor(
        private readonly translate: TranslateService,
        private readonly router: Router,
        public readonly loadingService: LoadingService,
        @Inject(PLATFORM_ID) platformId: any)
    {
        let firstNavigation = true;
        this.translate.addLangs([Language.PT, Language.EN]);

        this.isPlatformBrowser = isPlatformBrowser(platformId);

        if (this.isPlatformBrowser)
        {
            AOS.init({
                duration: 1500,
                once: true
            });
        }

        this.routeSubscription = this.router.events.subscribe((event: any) =>
        {
            if (event instanceof NavigationStart && firstNavigation)
            {
                this.globalUrl = event.url;

                if (this.globalUrl === '/')
                {
                    this.lang = this.getLanguageBrowser();
                    this.globalUrl = `/${this.lang}/homepage`;
                    this.router.navigateByUrl(this.globalUrl).catch(noop);
                }
                else
                {
                    this.lang = this.globalUrl.split('/')[1];
                    if (this.lang !== Language.PT && this.lang !== Language.EN)
                    {
                        this.lang = Language.PT;
                    }
                }

                this.translate.setDefaultLang(this.lang);
                this.translate.use(this.lang);

                firstNavigation = false;
            }
        });
    }

    private getLanguageBrowser(): string
    {
        let lang;

        if (this.isPlatformBrowser)
        {
            if (navigator.language)
            {
                lang = navigator.language.includes(Language.PT) ? Language.PT : Language.EN;

                lang = lang.includes(Language.PT) ? Language.PT : Language.EN;
            }
            else
            {
                lang = Language.PT;
            }
        }

        return lang;
    }

    public ngOnDestroy(): void
    {
        if (this.routeSubscription)
        {
            this.routeSubscription.unsubscribe();
        }
    }
}
