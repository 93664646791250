<div class="row d-flex justify-content-between history-pagination">
    <div class="col d-flex align-items-center justify-content-center pages">
        <svg-icon class="arrow-left" src="assets/images/double-chevron-left-big.svg" (click)="goFirstPage()"></svg-icon>
        <svg-icon class="arrow-left" src="assets/images/chevron-left-big.svg" (click)="goPreviousPage()"></svg-icon>
        <div class="d-flex pages-number">
            <div *ngFor="let numberOfPage of numbers">
                <div *ngIf="handlePagination(numberOfPage)" class="d-flex align-items-center justify-content-center page-number" [class.active]="isPageSelected(numberOfPage)" (click)="selectPage(numberOfPage)">{{ numberOfPage }}</div>
            </div>
        </div>
        <svg-icon class="arrow-right" src="assets/images/chevron-right-big.svg" (click)="goNextPage()"></svg-icon>
        <svg-icon class="arrow-right" src="assets/images/double-chevron-right-big.svg" (click)="goLastPage()"></svg-icon>
    </div>
</div>
