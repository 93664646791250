<nav id="top-bar" class="w-100 d-flex justify-content-between align-items-center" [class.up-level-cross]="menuOpen" [class.sticky-header]="canKeepTopBar()" [class.opened-first-nav-bar]="!isOffset0">
    <a [routerLink]="getLink(menuOptions.HOME)" class="logo">
        <svg-icon src="/assets/images/app-logo.svg"></svg-icon>
    </a>
    <div class="d-flex align-items-center g-24">
        <div class="d-flex align-items-center g-48">
            <ng-container *ngIf="isDesktopDevice()">
                <a class="menu-option" [routerLink]="getLink(menuOptions.ABOUT)" routerLinkActive="active-link">{{'MENU.ABOUT' | translate}}</a>
                <a class="menu-option" [routerLink]="getLink(menuOptions.MISSION)" routerLinkActive="active-link">{{'MENU.MISSION' | translate}}</a>
                <a class="menu-option" [routerLink]="getLink(menuOptions.SERVICES)" routerLinkActive="active-link">{{'MENU.SERVICES' | translate}}</a>
                <a class="menu-option" [routerLink]="getLink(menuOptions.EVENTS)" routerLinkActive="active-link">{{'MENU.EVENTS' | translate}}</a>
                <a class="menu-option" [routerLink]="getLink(menuOptions.NEWS)" routerLinkActive="active-link">{{'MENU.NEWS' | translate}}</a>
                <a class="menu-option" [routerLink]="getLink(menuOptions.CONTACTS)" routerLinkActive="active-link">{{'MENU.CONTACTS' | translate}}</a>
            </ng-container>
            <app-language-selector></app-language-selector>
        </div>
        <button *ngIf="!isDesktopDevice()" class="hamburger-menu position-relative d-flex flex-column" [ngClass]="{'menu-open': menuOpen}" (click)="openMenu()">
            <span class="cross-line w-100"></span>
            <span class="cross-line w-100"></span>
        </button>
    </div>
</nav>
<div @fadeInOutMenu class="mobile-menu position-fixed w-100 h-100" *ngIf="menuOpen">
    <div class="d-flex flex-column pt-130 justify-content-between h-100">
        <div class="d-flex flex-column g-30">
            <a class="mobile-menu-option" [routerLink]="getLink(menuOptions.ABOUT)" routerLinkActive="active-link" (click)="collapseMenu()">{{'MENU.ABOUT' | translate}}</a>
            <a class="mobile-menu-option" [routerLink]="getLink(menuOptions.MISSION)" routerLinkActive="active-link" (click)="collapseMenu()">{{'MENU.MISSION' | translate}}</a>
            <a class="mobile-menu-option" [routerLink]="getLink(menuOptions.SERVICES)" routerLinkActive="active-link" (click)="collapseMenu()">{{'MENU.SERVICES' | translate}}</a>
            <a class="mobile-menu-option" [routerLink]="getLink(menuOptions.EVENTS)" routerLinkActive="active-link" (click)="collapseMenu()">{{'MENU.EVENTS' | translate}}</a>
            <a class="mobile-menu-option" [routerLink]="getLink(menuOptions.NEWS)" routerLinkActive="active-link" (click)="collapseMenu()">{{'MENU.NEWS' | translate}}</a>
            <a class="mobile-menu-option" [routerLink]="getLink(menuOptions.CONTACTS)" routerLinkActive="active-link" (click)="collapseMenu()">{{'MENU.CONTACTS' | translate}}</a>
        </div>
        <div class="socials d-flex g-24">
            <a [href]="socialMedia.LINKEDIN" target="_blank" class="social-link">
                <img src="/assets/images/linkedin.svg" alt="{{'IMAGE_ALT.LINKEDIN' | translate}}" />
            </a>
            <a [href]="socialMedia.FACEBOOK" target="_blank" class="social-link">
                <img src="/assets/images/facebook.svg" alt="{{'IMAGE_ALT.FACEBOOK' | translate}}" />
            </a>
        </div>
    </div>
</div>