export enum MenuOptions
{
    ABOUT = 'about',
    MISSION = 'mission',
    SERVICES = 'services',
    EVENTS = 'events',
    NEWS = 'news',
    CONTACTS = 'contacts',
    HOME = 'homepage'
}
