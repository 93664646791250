// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    apiURL: 'https://backoffice.connect5.pt/wp-json/connect5/v1/',
    googleMapsApiKey: 'AIzaSyDj0-A5OB6eevsisaZ2T6tvKM7bC4eW_zY',
    emailConnect5: 'connect5@tice.pt',
    newsletterSubmissionLink: 'https://connect5.us17.list-manage.com/subscribe?u=a0ddf05e025a624019c469d57&id=d9bcb13048'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
