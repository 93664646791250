import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

@Injectable()
export class LoadingService 
{
    private requestCount$ = new BehaviorSubject<number>(0);
    public readonly isLoading = this.requestCount$.asObservable().pipe(map((requestCounter: number) => requestCounter > 0), distinctUntilChanged(), debounceTime(1)); 

    public showLoader(): void 
    {
        this.requestCount$.next(this.requestCount$.value + 1)
    }

    public hideLoader(): void 
    {
        setTimeout(() => 
        {
            this.requestCount$.next(Math.max(this.requestCount$.value - 1, 0))
        }, 500);
    }
}
