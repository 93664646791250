<div class="modal-detailed-info position-relative modal-lg">
    <h3 class="modal-label">
        {{'MISSION.MODAL.TITLE' | translate}}
    </h3>
    <div class="cross-holder position-absolute">
        <button class="cross d-flex flex-column position-abolute" (click)="dismiss()">
            <span class="cross-line w-100"></span>
            <span class="cross-line w-100"></span>
        </button>
    </div>
</div>
<div class="container-content" [ngClass]="{'d-flex': submitted, 'justify-content-center': submitted, 'align-items-center': submitted}">
    <ng-container *ngIf="!submitted">
        <div class="row d-flex flex-column justify-content-between">
            <form #aplicationForm class="container-info scroll" [formGroup]="applicationForm">
                <ul ngbNav #scrollNav #nav="ngbNav" class="nav-tabs d-flex flex-nowrap">
                    <li [ngbNavItem]="applicationModalTab.ORGANIZATION">
                        <button (click)="move(nav)" ngbNavLink>{{'MISSION.MODAL.TAB1.TITLE' | translate}}</button>
                        <ng-template ngbNavContent>
                        <div class="row fields">
                                <div class="col-lg col-md-12 field">                    
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB1.NAME_OF_ORGANIZATION' | translate }}</label>
                                        <input type="text" class="input" [placeholder]="'MISSION.MODAL.TAB1.NAME_OF_ORGANIZATION_PLACEHOLDER' | translate" formControlName="nameOfOrganization">
                                        <div class="d-flex error" *ngIf="showErrorInput('nameOfOrganization')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.GENERAL' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg col-md-12 field">                    
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB1.NIF' | translate }}</label>
                                        <input type="text" class="input" [placeholder]="'MISSION.MODAL.TAB1.NIF_PLACEHOLDER' | translate" formControlName="nif">
                                        <div class="d-flex error" *ngIf="showErrorInput('nif')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.NUMBER_NINE' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg col-md-12 field">                    
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB1.NUMBER_OF_EMPLOYEES' | translate }}</label>
                                        <input type="number" class="input" min="0" [placeholder]="'MISSION.MODAL.TAB1.NUMBER_OF_EMPLOYEES_PLACEHOLDER' | translate" formControlName="numberOfEmployees">
                                        <div class="d-flex error" *ngIf="showErrorInput('numberOfEmployees')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.GENERAL' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row fields">
                                <div class="col">
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB1.BUSINESS_OFFER' | translate }}</label>
                                        <input type="text" class="input" [placeholder]="'MISSION.MODAL.TAB1.BUSINESS_OFFER_PLACEHOLDER' | translate" formControlName="businessOffer">
                                        <div class="d-flex error" *ngIf="showErrorInput('businessOffer')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.GENERAL' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row fields">
                                <div class="col">
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB1.DIGITALIZATION_OPPORTUNITIES' | translate }}</label>
                                        <input type="text" class="input" [placeholder]="'MISSION.MODAL.TAB1.DIGITALIZATION_OPPORTUNITIES_PLACEHOLDER' | translate" formControlName="digitalizationOpportunities">
                                        <div class="d-flex error" *ngIf="showErrorInput('digitalizationOpportunities')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.GENERAL' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row fields">
                                <div class="col">
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB1.PUBLIC_FUNDING' | translate }}</label>
                                        <div class="d-flex funding-options">
                                            <div class="d-flex">
                                                <input type="radio" id="yes" name="publicFunding" value="yes" formControlName="publicFunding">
                                                <label for="yes">{{ 'GENERAL.YES' | translate }}</label>
                                            </div>
                                            <div class="d-flex">
                                                <input type="radio" id="no" name="publicFunding" value="no" formControlName="publicFunding">
                                                <label for="no">{{ 'GENERAL.NO' | translate }}</label>
                                            </div>
                                            <div class="d-flex">
                                                <input type="radio" id="NA" name="publicFunding" value="NA" formControlName="publicFunding">
                                                <label for="no">{{ 'GENERAL.NA' | translate }}</label>
                                            </div>
                                        </div>
                                        <div class="d-flex error" *ngIf="showErrorInput('publicFunding')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.OPTION' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="applicationModalTab.INFORMATION">
                        <button (click)="move(nav)" ngbNavLink>{{'MISSION.MODAL.TAB2.TITLE' | translate}}</button>
                        <ng-template ngbNavContent>
                            <div class="row fields">
                                <div class="col">
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB2.DIGITALIZATION_CHALLENGES_ORGANIZATION' | translate }}</label>
                                        <input type="text" class="input" [placeholder]="'MISSION.MODAL.TAB2.DIGITALIZATION_CHALLENGES_ORGANIZATION_PLACEHOLDER' | translate" formControlName="digitalizationChallengesOrganization">
                                        <div class="d-flex error" *ngIf="showErrorInput('digitalizationChallengesOrganization')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.GENERAL' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row fields">
                                <div class="col">
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB2.SPECIFIC_DIGITALIZATION_CASE' | translate }}</label>
                                        <input type="text" class="input" [placeholder]="'MISSION.MODAL.TAB2.SPECIFIC_DIGITALIZATION_CASE_PLACEHOLDER' | translate" formControlName="specificDigitalizationCase">
                                        <div class="d-flex error" *ngIf="showErrorInput('specificDigitalizationCase')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.GENERAL' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row fields">
                                <div class="col">
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB2.DIGITALIZE_TEAM_RESOURCES' | translate }}</label>
                                        <input type="text" class="input" [placeholder]="'MISSION.MODAL.TAB2.DIGITALIZE_TEAM_RESOURCES_PLACEHOLDER' | translate" formControlName="digitalizeTeamResources">
                                        <div class="d-flex error" *ngIf="showErrorInput('digitalizeTeamResources')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.GENERAL' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row fields">
                                <div class="col">
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB2.EXPECTED_RESULTS_DIGITALIZE_MISSION' | translate }}</label>
                                        <input type="text" class="input" [placeholder]="'MISSION.MODAL.TAB2.EXPECTED_RESULTS_DIGITALIZE_MISSION_PLACEHOLDER' | translate" formControlName="expectedResultsDigitalizeMission">
                                        <div class="d-flex error" *ngIf="showErrorInput('expectedResultsDigitalizeMission')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.GENERAL' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row fields">
                                <div class="col">
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB2.SUPPORT_NEEDED_DIGITALIZE_MISSION_RESULTS' | translate }}</label>
                                        <input type="text" class="input" [placeholder]="'MISSION.MODAL.TAB2.SUPPORT_NEEDED_DIGITALIZE_MISSION_RESULTS_PLACEHOLDER' | translate" formControlName="supportNeededDigitalizeMissionResults">
                                        <div class="d-flex error" *ngIf="showErrorInput('supportNeededDigitalizeMissionResults')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.GENERAL' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="applicationModalTab.CONTACT">
                        <button (click)="move(nav)" ngbNavLink>{{'MISSION.MODAL.TAB3.TITLE' | translate}}</button>
                        <ng-template ngbNavContent>
                            <div class="row fields">
                                <div class="col">
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB3.NAME_OF_CONTACT' | translate }}</label>
                                        <input type="text" class="input" [placeholder]="'MISSION.MODAL.TAB3.NAME_OF_CONTACT_PLACEHOLDER' | translate" formControlName="nameOfContact">
                                        <div class="d-flex error" *ngIf="showErrorInput('nameOfContact')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.GENERAL' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row fields">
                                <div class="col-lg col-md-12 field">
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB3.ROLE_OF_CONTACT' | translate }}</label>
                                        <input type="text" class="input" [placeholder]="'MISSION.MODAL.TAB3.ROLE_OF_CONTACT_PLACEHOLDER' | translate" formControlName="roleOfContact">
                                        <div class="d-flex error" *ngIf="showErrorInput('roleOfContact')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.GENERAL' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg col-md-12 field">
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB3.EMAIL_OF_CONTACT' | translate }}</label>
                                        <input type="email" class="input" [placeholder]="'MISSION.MODAL.TAB3.EMAIL_OF_CONTACT_PLACEHOLDER' | translate" formControlName="emailOfContact">
                                        <div class="d-flex error" *ngIf="showErrorInput('emailOfContact')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.EMAIL' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col field">
                                    <div class="d-flex flex-column">
                                        <label class="label">{{ 'MISSION.MODAL.TAB3.PHONE_OF_CONTACT' | translate }}</label>
                                        <input type="text" class="input" [placeholder]="'MISSION.MODAL.TAB3.PHONE_OF_CONTACT_PLACEHOLDER' | translate" formControlName="phoneOfContact">
                                        <div class="d-flex error" *ngIf="showErrorInput('phoneOfContact')">
                                            <svg-icon src="assets/images/error-info.svg"></svg-icon>
                                            <span class="label">{{ 'MISSION.MODAL.ERROR.PHONE_NUMBER' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </form>
            <div class="modal-footer d-flex justify-content-end navigation">
                <ng-container *ngIf="!isMobile()">
                    <button class="previous" (click)="cancel()" *ngIf="showButton(nav, modalNavigation.CANCEL)">{{ 'GENERAL.CANCEL' | translate }}</button>
                </ng-container>
                <button class="previous" (click)="previous(nav)" *ngIf="showButton(nav, modalNavigation.PREVIOUS)">{{ 'GENERAL.BACK' | translate }}</button>
                <button class="next" (click)="next(nav)" *ngIf="showButton(nav, modalNavigation.NEXT)">{{ 'GENERAL.NEXT' | translate }}</button>
                <button class="next" [disabled]="submiting" (click)="onSubmit(nav)" *ngIf="showButton(nav, modalNavigation.SUBMIT)">{{ 'GENERAL.SUBMIT' | translate }}</button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="submitted">
        <div class="row">
            <div class="col d-flex flex-column align-items-center justify-content-center">
                <div class="d-flex flex-column align-items-center success">
                    <svg-icon src="assets/images/form-success.svg"></svg-icon>
                    <span>{{ 'MISSION.MODAL.SUCCESS' | translate }}</span>
                </div>
            </div>
        </div>
    </ng-container>
</div>
