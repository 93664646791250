import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GovernanceAreas} from '@shared/enums/governance-area.enum';
import {Member} from './member';

@Component({
    selector: 'app-governance-modal',
    templateUrl: './governance-modal.component.html',
    styleUrls: ['./governance-modal.component.scss']
})
export class GovernanceModalComponent implements OnInit
{
    @Input() public governanceArea: GovernanceAreas;
    public memberList: Member[] = [];

    public constructor(private readonly activeModal: NgbActiveModal)
    { }

    public ngOnInit(): void
    {
        if (this.governanceArea === GovernanceAreas.GENERAL_COUNCIL)
        {
            this.memberList = [
                {
                    name: 'IPV',
                    company: 'Intituto Politécnico de Viseu',
                    function: 'Presidente'
                },
                {
                    name: 'CEiiA',
                    company: 'Centre of Engineering and Product Development'
                },
                {
                    name: 'DTX',
                    company: 'Laboratório Colaborativo em Transformação Digital'
                },
                {
                    name: 'IPL',
                    company: 'Intituto Politécnico de Leiria'
                },
                {
                    name: 'IPP',
                    company: 'Intituto Politécnico do Porto'
                },
                {
                    name: 'IT',
                    company: 'Intituto de Telecomunicações'
                },
                {
                    name: 'UA',
                    company: 'Universidade de Aveiro'
                },
                {
                    name: 'UBI',
                    company: 'Universidade da Beira Interior'
                },
                {
                    name: 'UM',
                    company: 'Universidade do Minho'
                },
                {
                    name: 'UP',
                    company: 'Universidade do Porto'
                },
                {
                    name: 'TICE.PT',
                    company: 'Pólo das Tecnologias de Informação, Comunicação e Electrónica'
                }
            ];
        }
        else if (this.governanceArea === GovernanceAreas.EXECUTIVE_COMMISSION)
        {
            this.memberList = [
                {
                    name: 'TICE.PT',
                    company: 'Pólo das Tecnologias de Informação, Comunicação e Electrónica',
                    function: 'Presidente'
                },
                {
                    name: 'IT',
                    company: 'Intituto de Telecomunicações'
                },
                {
                    name: 'UA',
                    company: 'Universidade de Aveiro'
                },
                {
                    name: 'UBI',
                    company: 'Universidade da Beira Interior'
                }
            ];
        }
        else
        {
            this.memberList = [
                {
                    name: 'Rui Esteves',
                    company: 'Engº - Altice Labs',
                    avatar: 'rui-esteves',
                    function: 'Presidente'
                },
                {
                    name: 'Maria Manuela Ribeiro',
                    company: 'Coordenadora de Inovação - UA',
                    avatar: 'maria-ribeiro'
                },
                {
                    name: 'Rui Pereira',
                    company: 'Entidade/Função',
                    avatar: 'rui-pereira'
                },
                {
                    name: 'Ângela Dias',
                    company: 'Entidade/Função',
                    avatar: 'angela-dias'
                }
            ];
        }
    }

    public dismiss(): void
    {
        this.activeModal.dismiss();
    }
}
