import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MenuOptions} from '@shared/enums/menu-options.enum';
import {PageNotFoundComponent} from '@shared/page-not-found/page-not-found.component';

const routes: Routes =
    [
        {
            path: ':lang',
            children:
                [
                    {
                        path: '',
                        redirectTo: MenuOptions.HOME,
                        pathMatch: 'full'
                    },
                    {
                        path: MenuOptions.HOME,
                        loadChildren: (): Promise<any> => import('./homepage/homepage.module').then((m) => m.HomepageModule)
                    },
                    {
                        path: MenuOptions.ABOUT,
                        loadChildren: (): Promise<any> => import('./about/about.module').then((m) => m.AboutModule)
                    },
                    {
                        path: MenuOptions.SERVICES,
                        loadChildren: (): Promise<any> => import('./service/service.module').then((m) => m.ServiceModule)
                    },
                    {
                        path: MenuOptions.CONTACTS,
                        loadChildren: (): Promise<any> => import('./contacts/contacts.module').then((m) => m.ContactsModule)
                    },
                    {
                        path: MenuOptions.MISSION,
                        loadChildren: (): Promise<any> => import('./mission/mission.module').then((m) => m.MissionModule)
                    },
                    {
                        path: MenuOptions.EVENTS,
                        loadChildren: (): Promise<any> => import('./events/events.module').then((m) => m.EventsModule)
                    },
                    {
                        path: MenuOptions.NEWS,
                        loadChildren: (): Promise<any> => import('./news/news.module').then((m) => m.NewsModule)
                    },
                    {
                        path: 'page-not-found',
                        component: PageNotFoundComponent
                    },
                    {
                        path: '**',
                        redirectTo: 'page-not-found'
                    }
                ]
        }

    ];

@NgModule({
    imports:
        [
            RouterModule.forRoot(routes,
                {
                    scrollPositionRestoration: 'top',
                    anchorScrolling: 'enabled'
                })
        ],
    exports:
        [
            RouterModule
        ]
})
export class RoutingModule
{ }
