<div *ngIf="hasFileLink; else NoFileLink">
    <a class="d-flex align-items-center custom-button" [class.custom-button]="customButton" href="{{linkReference}}"
        target="_blank" *ngIf="linkReference">
        {{message | translate}}
        <div class="d-flex animated-arrow justify-content-end" *ngIf="showIcon">
            <svg-icon src="/assets/images/arrow-right.svg"></svg-icon>
            <svg-icon src="/assets/images/arrow-right.svg"></svg-icon>
        </div>
    </a>
</div>
<ng-template #NoFileLink>
    <a class="d-flex align-items-center" [class.custom-button]="customButton" (click)="navigateToLink()"
        *ngIf="linkReference">
        {{message | translate}}
        <div class="d-flex animated-arrow justify-content-end" *ngIf="showIcon">
            <svg-icon src="/assets/images/arrow-right.svg"></svg-icon>
            <svg-icon src="/assets/images/arrow-right.svg"></svg-icon>
        </div>
    </a>
    <button class="d-flex align-items-center custom-button" (click)="navigateTo()" *ngIf="!linkReference"
        [ngClass]="{'banner-button': !linkReference}">
        {{message | translate}}
        <div class="d-flex animated-arrow justify-content-end" *ngIf="showIcon">
            <svg-icon src="/assets/images/arrow-right.svg"></svg-icon>
            <svg-icon src="/assets/images/arrow-right.svg"></svg-icon>
        </div>
    </button>
</ng-template>