import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ScreenDimensions} from '@shared/enums/screen-dimensions.enum';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges
{
    @Output() public pageChange: EventEmitter<{
        currentPage: number;
        size: number;
    }> = new EventEmitter<{
        currentPage: number;
        size: number;
    }>();
    @Input() public totalItems: number;
    @Input() public selectShowingItems: number;
    @Input() public currentPage = 1;

    public numbers: number[];
    public numberOfPages: number;

    public ngOnChanges(): void
    {
        this.numberOfPages = Math.ceil(this.totalItems / this.selectShowingItems);
        this.numbers = Array(this.numberOfPages).fill(0).map((_, index) => index + 1);
    }

    public onPageChange(): void
    {
        this.pageChange.emit({
            currentPage: this.currentPage,
            size: this.selectShowingItems
        });
    }

    public isPageSelected(page: number): boolean
    {
        return this.currentPage === page;
    }

    public selectPage(page: number): void
    {
        this.currentPage = page;
        this.onPageChange();
    }

    public goNextPage(): void
    {
        if (this.currentPage < this.numberOfPages)
        {
            this.currentPage++;
            this.onPageChange();
        }
    }

    public goPreviousPage(): void
    {
        if (this.currentPage > 1)
        {
            this.currentPage--;
            this.onPageChange();
        }
    }

    public goFirstPage(): void
    {
        this.currentPage = 1;
        this.onPageChange();
    }

    public goLastPage(): void
    {
        this.currentPage = this.numberOfPages;
        this.onPageChange();
    }

    public handlePagination(page): boolean
    {
        let showPages: boolean;

        const pagesToShow: number = window.innerWidth < ScreenDimensions.TABLET ? 3 : 5;
        const totalPagesToShow: number = pagesToShow - 1;
        const morePagesToShow: number = window.innerWidth < ScreenDimensions.TABLET ? 1 : 2;

        if (this.numberOfPages <= pagesToShow)
        {
            showPages = true;
        }
        else
        {
            if (this.currentPage <= totalPagesToShow)
            {
                showPages = page <= pagesToShow;
            }
            else if (this.currentPage >= this.numberOfPages - morePagesToShow)
            {
                showPages = page >= this.numberOfPages - totalPagesToShow;
            }
            else
            {
                showPages = page >= this.currentPage - morePagesToShow && page <= this.currentPage + morePagesToShow;
            }
        }

        return showPages;
    }
}
