import {Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Content} from '@shared/domain/content';
import {CardType} from '@shared/enums/card-type.enum';

@Component({
    selector: 'app-custom-card',
    templateUrl: './custom-card.component.html',
    styleUrls: ['./custom-card.component.scss']
})
export class CustomCardComponent
{
    @Input() public content: Content;
    @Input() public cardType: CardType;
    public image: string;
    public placeholderImage = '/assets/images/placeholder-image.webp';

    public constructor(
        private readonly translateService: TranslateService
    )
    { }

    public generateEventsUrl(url: string): string
    {
        return `/${this.translateService.currentLang}/events/${url}`;
    }

    public getImage(featuredImage: string): string
    {
        return featuredImage !== '' ? featuredImage : this.placeholderImage;
    }

    public isNews(): boolean
    {
        return this.cardType === CardType.NEWS;
    }
}
