import {animate, state, style, transition, trigger} from '@angular/animations';
import {isPlatformBrowser} from '@angular/common';
import {Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SocialMedia} from '@shared/domain/social-media';
import {MenuOptions} from '@shared/enums/menu-options.enum';
import {ScreenDimensions} from '@shared/enums/screen-dimensions.enum';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [
        trigger('fadeInOutMenu', [
            state('void', style({
                top: '43px',
                right: '47px',
                opacity: 0,
                clipPath: 'inset(0% 0% 100% 100% round 24px)'
            })),
            state('*', style({
                top: 0,
                right: 0,
                opacity: 1,
                clipPath: 'inset(0px)'
            })),
            transition('void => *', animate('350ms')),
            transition('* => void', animate('350ms'))
        ])
    ]
})
export class HeaderComponent implements OnInit
{
    private readonly isBrowserPlatform: boolean;
    public menuOptions = MenuOptions;
    private lastScroll = 0;
    private windowWidth = 0;
    public menuOpen = false;
    public isOffset0 = true;
    public socialMedia = SocialMedia;

    public constructor(
        private readonly renderer: Renderer2,
        @Inject(PLATFORM_ID) platformId: any,
        private readonly translate: TranslateService
    )
    {
        this.isBrowserPlatform = isPlatformBrowser(platformId);
    }

    public ngOnInit(): void
    {
        this.updateWindowWidth();
    }

    @HostListener('window:scroll', ['$event'])
    public onWindowScroll(): void
    {
        this.setDisplay();
    }

    private setDisplay(): void
    {
        if (this.isBrowserPlatform)
        {
            if (window.scrollY <= 106)
            {
                this.isOffset0 = true;
            }
            else
            {
                this.isOffset0 = window.scrollY < this.lastScroll;
                this.lastScroll = window.scrollY;
            }
        }
    }

    @HostListener('window:resize', ['$event'])
    public onWindowResize(): void
    {
        this.updateWindowWidth();
    }

    private updateWindowWidth(): void
    {
        if (this.isBrowserPlatform)
        {
            this.windowWidth = window.innerWidth;
        }
    }

    public isDesktopDevice(): boolean
    {
        return this.isBrowserPlatform && this.windowWidth > ScreenDimensions.LG;
    }

    public openMenu(): void
    {
        this.menuOpen = !this.menuOpen;

        if (this.menuOpen)
        {
            this.renderer.addClass(document.body, 'no-scroll');
        }
        else
        {
            this.renderer.removeClass(document.body, 'no-scroll');
        }
    }

    public getLink(websiteSection: MenuOptions): string
    {
        return `/${this.translate.currentLang}/${websiteSection}`;
    }

    public collapseMenu(): void
    {
        this.menuOpen = false;
        document.body.classList.remove('no-scroll');
    }

    public canKeepTopBar(): boolean
    {
        return this.isBrowserPlatform && window.scrollY !== 0;
    }
}
