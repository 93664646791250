<div class="banner"></div>
<div class="not-found-container d-flex">
    <div class="information-section d-flex flex-column">
        <div>
            <h3 class="title">
                {{'NOT_FOUND.TITLE' | translate}}
            </h3>
            <h4 class="subtitle">
                {{'NOT_FOUND.SUBTITLE' | translate}}
            </h4>
        </div>
        <p class="description">
            {{'NOT_FOUND.DESCRIPTION' | translate}}
        </p>
        <app-custom-button [linkReference]="getHomeUrl()" message="BUTTONS.BACK" data-aos="fade-up"></app-custom-button>
    </div>
    <div class="not-found-image d-flex justify-content-center align-items-center">
        <svg-icon src="/assets/images/not-found.svg"></svg-icon>
    </div>
</div>