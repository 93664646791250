import {Component} from '@angular/core';
import {interval} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss']
})

export class PreloaderComponent
{
    public readonly loadingPercentage$ = interval(1).pipe(map((value) => Math.min(value * 10, 100)));
}
