<div class="custom-card d-flex flex-column">
    <img class="custom-card-image w-100" [src]="getImage(content.featuredImage)" alt="">
    <div class="custom-card-information d-flex flex-column justify-content-between w-100">
        <div class="d-flex flex-column g-18">
            <p class=" custom-card-date">
                {{content.date | date:'mediumDate'}}
            </p>
            <h3 class="custom-card-title" *ngIf="content.title">
                {{content.title}}
            </h3>
            <div class="custom-card-description" *ngIf="content.description && isNews()" [innerHTML]="content.description"></div>
        </div>
        <div class="custom-card-footer d-flex align-items-center justify-content-end">
            <p class="custom-card-author" *ngIf="content.author">
                {{'NEWS.BY' | translate}} {{content.author}}
            </p>
            <app-navigation-button *ngIf="content.url" iconSrc="/assets/images/arrow-right.svg" urlSrc="{{generateEventsUrl(content.url)}}"></app-navigation-button>
        </div>
    </div>
</div>
