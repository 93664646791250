import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService 
{
    public constructor(
        private readonly httpClient: HttpClient
    ) 
    { }

    public sendEmail(body: any): Observable<any>
    {
        return this.httpClient.post(`${environment.apiURL}send-email`, body);
    }
}
