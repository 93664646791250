import {isPlatformBrowser} from '@angular/common';
import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {install} from 'ga-gtag';
import {CookieService} from 'ngx-cookie-service';

@Component({
    selector: 'app-cookie-bar',
    templateUrl: './cookie-bar.component.html',
    styleUrls: ['./cookie-bar.component.scss']
})
export class CookieBarComponent implements OnInit
{
    public hasConsent: boolean;
    private isPlatformBrowser = false;

    public constructor(private cookieService: CookieService,
        @Inject(PLATFORM_ID) platformId: any)
    {
        this.isPlatformBrowser = isPlatformBrowser(platformId);
    }

    public ngOnInit(): void 
    {
        const consentCookie = this.cookieService.get('consent');
        if (consentCookie)
        {
            this.hasConsent = consentCookie === 'true';

            if (this.hasConsent)
            {
                this.startAnalytics();
            }
        }
    }

    public setCookie(consent: boolean): void
    {
        const expireTime = new Date();
        expireTime.setFullYear(expireTime.getFullYear() + 1);

        this.hasConsent = consent;
        this.cookieService.set('consent', `${consent}`, {expires: expireTime});

        if (consent)
        {
            this.startAnalytics();
        }

    }

    public startAnalytics(): void
    {
        if (this.isPlatformBrowser)
        {
            // Google Analytics Configuration
            install('G-BJKEL3K58Y');
        }
    }

    public canShowCookieBar(): boolean
    {
        return this.hasConsent === undefined;
    }
}
