import {Component, Input, OnChanges} from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges
{
    @Input() public isBrowserPlatform: boolean;
    @Input() public progress = 0;

    public ngOnChanges(): void
    {
        if (this.isBrowserPlatform)
        {
            document.documentElement.style.setProperty('--progress-bar-width', `${this.progress * 100}%`);
        }
    }
}
